/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-07 15:48:15 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-09-24 17:03:50
 */
<template>
  <div class="practice">
    <div class="rollUp_cont_first">
      <div class="rollUp_cont_first_top">
        <div
          :class="masterIndex == i ? ' act' : ''"
          v-for="(m, i) in masterState"
          :key="i"
          @click="masterClick(i)"
        >
          {{ m }}
        </div>
      </div>
      <div class="he_10" style="margin: 0 -30px"></div>
      <ul v-if="list.length > 0">
        <li v-for="(li, i) in list" :key="i" @click="newWordBook(li)">
          <div class="name">
            <span>{{ li.Name }}</span>
            <span>{{ li.WordsCategoryName }}</span>
          </div>
          <div class="pronunciation">
            {{ li.Phonetic }}
            <img
              src="@/assets/images/icon_audio.png"
              class="icon_audio"
              alt=""
              srcset=""
              @click="play(li.Name)"
            />
          </div>
          <!-- <p class="notes" v-html="li.Explain"></p> -->
          <p class="time">{{ li.CreateTime }}</p>
          <div class="he_10" style="margin: 0 -45px"></div>
        </li>
      </ul>
      <div v-else>
        <no-data></no-data>
      </div>
    </div>
    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="page"
      :page-size="pageSize"
      layout="prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import { queryWordsBook } from "@/api/questionBank";
import noData from "@/components/noData";
export default {
  data () {
    return {
      page: 1,
      pageSize: 10,
      total: 0,
      list: [],
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      masterIndex: 0,
      masterState: ["认识", "不认识", "模糊"],
    };
  },
  components: {
    noData
  },
  created () { },
  mounted () {
    this.route = this.$route.query
    this.init();
  },
  watch: {
    $route: "init",
  },
  methods: {
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.init();
    },
    masterClick (i) {
      this.list = [];
      this.page = 1;
      this.masterIndex = i
      this.init()
    },
    //播放词语
    play (name) {
      this.$refs.audio.src =
        "https://dict.youdao.com/speech?audio=" + name;
      this.$refs.audio.play();
    },
    // 生词本跳转
    newWordBook (obj) {
      let ob = {
        UserID: this.userInfo.Id,
        courseId: this.route.CourseId,
        title: "生词本",
        Id: obj.Id,
        type: 3,
      }
      this.goVocabulary(ob)
    },
    goVocabulary (obj) {
      let url = "/onlineQuestionBank/vocabulary"
      this.$router.push({ path: url, query: obj });
    },
    async init () {
      let parm = {
        studentId: this.userInfo.Id,
        courseId: this.route.CourseId,
        pageIndex: this.page,
        pageSize: this.pageSize,
        answer: this.masterIndex
      }
      const res = await queryWordsBook(parm);
      if (res.success == true) {
        this.list = res.response.data
        this.total = res.response.dataCount
      }
    },
  },
};
</script>

<style lang="less" scoped>
.practice {
  .rollUp_cont_first {
    .rollUp_cont_first_top {
      font-size: 15px;
      height: 45px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: space-around;
      background: #fff;
      margin-top: 10px;
      .act {
        color: #3a78f9;
        position: relative;
        &::before {
          display: inline-block;
          content: " ";
          width: 20px;
          height: 3px;
          background: #3a78f9;
          position: absolute;
          left: 0;
          right: 0;
          margin: 0 auto;
          bottom: -10px;
        }
      }
    }
    ul {
      li {
        // padding: 15px;
        background-color: #fff;
        margin-top: 8px;
        .name {
          font-size: 16px;
          font-family: DIN;
          font-weight: bold;
          color: #3a78f9;
          line-height: 1;
          display: flex;
          justify-content: space-between;
          align-items: center;
          span {
            &:nth-child(2) {
              font-size: 14px;
              color: #999999;
            }
          }
        }
        .pronunciation {
          line-height: 1.5;
          margin: 10px 0 10px;
        }
        .notes {
          line-height: 1.5;
        }
        .time {
          font-size: 13px;
          color: #999999;
          line-height: 1;
          margin: 10px 0;
          // margin-top: 10px;
        }
        .icon_audio {
          width: 15px;
          vertical-align: text-bottom;
          margin: 0 5px;
        }
      }
    }
  }
}
</style>